import { Price } from '~source/core/models/components/atoms/price';

export default function transformPricesResponseToPrice(
  id: number,
  prices: EVA.Core.PriceInfo[] | undefined,
): Price | null {
  if (!prices || !id) return null;

  const matchingProduct = prices.filter(({ ProductID }) => ProductID === id);
  const priceOfProduct = matchingProduct.find(
    ({ PriceListUsageTypeName }) => PriceListUsageTypeName === 'Sales',
  );

  return {
    original:
      // @ts-ignore Does exist but not typed by EVA
      priceOfProduct?.OriginalPriceInTax ?? priceOfProduct?.PriceInTax ?? null,
    // @ts-ignore Does exist but not typed by EVA
    sale: priceOfProduct?.OriginalPriceInTax ? priceOfProduct.PriceInTax : null,
  };
}

import * as React from 'react';

export default function useEvent<T, A extends any[]>(
  callback: (...args: A) => T,
) {
  const callbackRef = React.useRef(callback);

  React.useEffect(() => {
    callbackRef.current = callback;
  });

  const effect = React.useCallback(
    (...args: A) => callbackRef.current(...args),
    [],
  );

  return effect;
}

import { useEffect, useState } from 'react';
import { ProductDetails } from '~source/core/models/components/organisms/product-details';
import { LocaleLanguageType } from '~source/core/models/unions/locale';
import { getProductDetails } from '~source/core/services/eva/api/product/get-product-details';
import useEvent from '~source/ui/hooks/helper/useEvent';
import retrieveThrownErrorMessage from '~source/ui/utils/getters/retrieve-thrown-error-message';

export default function useProductDetails(
  productId: number | null,
  locale: LocaleLanguageType,
) {
  const [status, setStatus] = useState<'idle' | 'pending' | 'error'>('idle');
  const [value, setValue] = useState<ProductDetails | null>(null);
  const [error, setError] = useState<null | string>(null);

  const execute = useEvent(async () => {
    if (!productId) return;
    setStatus('pending');
    setValue(null);
    setError(null);

    try {
      const productDetail = await getProductDetails(productId, locale);
      setValue(productDetail);
      setStatus('idle');
    } catch (err: any) {
      setError(retrieveThrownErrorMessage(err));
      setStatus('error');
    }
  });

  useEffect(() => {
    execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId, locale]);

  return { status, value, error };
}

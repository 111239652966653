/* eslint-disable camelcase */
import { AvailabilityIndication } from '~source/core/models/components/atoms/availability-indication';
import { Badge } from '~source/core/models/components/atoms/badge';
import transformPricesResponseToPrice from '~source/core/transformers/product/transform-prices-response-to-price';
import getAvailabilityOfProduct from '~source/ui/utils/getters/get-availability-of-product';

export function transformBadge({
  lines,
  availabilityOfProducts,
  badge,
  prices,
}: {
  badge: EVA.Core.GetBundleProductProduct;
  lines: EVA.Core.BundleProductLine[];
  prices?: EVA.Core.PriceInfo[];
  availabilityOfProducts: AvailabilityIndication | null;
}): Badge | null {
  if (!badge || !lines?.length) return null;
  const { display_value, marketing_titel, product_id: id } = badge.Content;

  // The badges in array "badges" do not contain the ProductBundlesLineID
  // These can be found in a seperate array "lines"
  const getProductBundleId: () => number | null = () => {
    let productBundleLineId = null;

    // The badges themselves have ProductBundleLineID's but you need the parent ID as ProductBundleLineID
    // But to get to the parent you have to know which object in the lines array contains badges
    // But the badges are deeply nested, Inception Developer Edition
    // So this piece of code looks very daunting
    const badgeParent = lines.find((line) => {
      if (!line.Options) return undefined;
      const badgesObject = line.Options.find(
        (option) => option.ProductID === id,
      );
      return badgesObject;
    });
    if (badgeParent) productBundleLineId = badgeParent.ID;
    return productBundleLineId;
  };
  const { availability } = getAvailabilityOfProduct(
    availabilityOfProducts,
    id,
    badge.Type,
  );
  const productBundleLineId = getProductBundleId();
  if (!productBundleLineId) return null;

  return {
    productBundleLineId,
    id,
    name: marketing_titel || display_value,
    price: transformPricesResponseToPrice(id, prices),
    availability,
  };
}

export default function transformBadges({
  badges,
  lines,
  prices,
  availabilityOfProducts,
}: {
  badges: EVA.Core.GetBundleProductProduct[];
  lines: EVA.Core.BundleProductLine[];
  prices?: EVA.Core.PriceInfo[];
  availabilityOfProducts: AvailabilityIndication | null;
}): Badge[] | null {
  if (!badges?.length) return null;
  const transformedBadges = badges.map((badge) =>
    transformBadge({
      availabilityOfProducts,
      lines,
      badge,
      prices,
    }),
  );
  const filteredBadges = transformedBadges.filter(
    (badge) => badge !== null,
  ) as Badge[];
  return filteredBadges;
}

import {
  SizeModel,
  SizeModelConfigurable,
} from '~source/core/models/components/atoms/size';

const shoeSizes = [
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
  '33',
  '34',
  '35',
  '36',
  '36 1/3',
  '36 2/3',
  '37',
  '37 1/3',
  '37 2/3',
  '38',
  '38 1/3',
  '38 2/3',
  '39',
  '39 1/3',
  '39 2/3',
  '40',
  '40 1/3',
  '40 2/3',
  '41',
  '41 1/3',
  '41 2/3',
  '42',
  '42 1/3',
  '42 2/3',
  '43',
  '43 1/3',
  '43 2/3',
  '44',
  '44 1/3',
  '44 2/3',
  '45',
  '45 1/3',
  '45 2/3',
  '46',
  '46 1/3',
  '46 2/3',
  '47',
  '47 1/3',
  '47 2/3',
  '48',
  '48 1/3',
  '48 2/3',
  '49',
  '49 1/3',
  '49 2/3',
  '50',
  '50 1/3',
  '50 2/3',
];
const sockSizes = [
  '23-26',
  '27-30',
  '31-33',
  '34-36',
  '37-39',
  '40-42',
  '43-45',
  '46-48',
];
const clothingSizes = ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL'];

export default function sortSizes(
  sizes: (SizeModel | SizeModelConfigurable)[],
) {
  const sizeOrder = [...shoeSizes, ...clothingSizes, ...sockSizes];

  return sizes.sort((a, b) =>
    sizeOrder.indexOf(a.label && b.label) > 0
      ? sizeOrder.indexOf(a.label) - sizeOrder.indexOf(b.label)
      : parseInt(a.label, 10) - parseInt(b.label, 10),
  );
}

import { useMemo } from 'react';
import { cx } from '~source/ui/utils/join-classnames';
import $ from './price-display.module.scss';

type Props = {
  isSalePrice?: boolean;
  price: number | string;
  className?: string;
  discounted?: boolean;
  size?: 'big' | 'normal' | 'small';
};

export default function PriceDisplay({
  price,
  className,
  discounted,
  size,
  isSalePrice,
}: Props) {
  const [euros, cents] = useMemo(() => {
    if (typeof price === 'string') {
      return price.split(',');
    }
    return price.toFixed(2).split('.') || ['0', '00'];
  }, [price]);
  const shouldShowHyphen = cents === '00';

  return (
    <span
      className={cx(
        $.wrapper,
        className,
        discounted && $.isDiscounted,
        isSalePrice && $.isSalePrice,
        size === 'small' && $.isSmall,
        size === 'big' && $.isBig,
      )}
      aria-label={discounted ? undefined : `€ ${euros}.${cents}`}
      aria-hidden={discounted ? 'true' : undefined}
    >
      <span className={$.euros}>{euros}</span>
      <span className={$.comma} />
      {shouldShowHyphen ? (
        <span className={$.hyphen} />
      ) : (
        <span className={$.cents}>{cents}</span>
      )}
    </span>
  );
}

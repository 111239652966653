import { ORGANIZATION_UNIT_ID } from '~source/constants';
import { EVA_DEFAULT_PRODUCT_PROPERTIES } from '~source/constants/eva';
import { ProductDetails } from '~source/core/models/components/organisms/product-details';
import { LocaleLanguageType } from '~source/core/models/unions/locale';
import {
  getEvaService,
  Core,
} from '~source/core/services/eva/api/get-eva-service';
import getProductAvailability from '~source/core/services/eva/api/product/get-product-availability';
import transformExtendedProductDetail from '~source/core/transformers/product/transform-extended-product-detail';
import parseNumber from '~source/core/utils/parse-number';
import {
  isBundle,
  isConfigurable,
  isStock,
} from '~source/ui/utils/checks/is-product-type';

const isBundleProduct = (product: EVA.Core.GetProductDetailResponse) => {
  const productTypes: number[] = product.Result?.product_types || [];
  return isBundle(productTypes);
};

const isConfigurableProduct = (product: EVA.Core.GetProductDetailResponse) => {
  const productTypes: number[] = product.Result?.product_types || [];
  return isConfigurable(productTypes);
};

const getStockHoldingProductIds = (
  products: EVA.Core.ConfigurableProductDto[],
): number[] => {
  // Add the product id itself and recursively add the child item ids
  const productIds: number[] = [];
  products.forEach((product) => {
    // Stock holding means not being configurable themselves
    if (isStock([product.Type])) {
      productIds.push(product.ProductID);
    }
    const childProductIds = getStockHoldingProductIds(product.Children);
    productIds.push(...childProductIds);
  });
  return productIds;
};

export async function getProductDetail(
  productId: number,
  locale: LocaleLanguageType,
  isRelatedProduct = false,
): Promise<ReturnType<typeof transformExtendedProductDetail>> {
  const details = await getEvaService(
    Core.GetProductDetail,
    { ID: productId },
    { locale },
  );
  if (!details) throw Error(`[getProductDetail] Couldn't get "${productId}"`);

  // We are going to collect all product ID's here for when we are going to calculate the prices and availability
  const allProductIds = [productId];

  const [bundleDetails, configurableDetails, ...relationsDetails] =
    await Promise.all([
      isBundleProduct(details)
        ? getEvaService(
            Core.GetBundleProductDetails,
            {
              BundleProductID: productId,
              IncludedFields: EVA_DEFAULT_PRODUCT_PROPERTIES,
            },
            { locale },
          )
        : undefined,
      isConfigurableProduct(details)
        ? getEvaService(
            Core.GetConfigurableProductDetail,
            {
              ID: productId,
            },
            { locale },
          )
        : undefined,
      ...(!isRelatedProduct && details.Relations?.length
        ? details.Relations
            // Retieve the details for each related product
            .map(async (relation) => {
              const relationProductId =
                relation.PrimaryProductID === productId
                  ? relation.RelatedProductID
                  : relation.PrimaryProductID;
              if (!relationProductId) return null;

              return getProductDetail(relationProductId, locale, true).catch(
                () => null,
              );
            })
        : []),
    ]);

  if (bundleDetails) {
    bundleDetails.Products.forEach((bundleProduct) => {
      // Add the product ID for the product availability and prices request
      allProductIds.push(bundleProduct.ID);
    });
  }
  if (configurableDetails) {
    const stockHoldingProductIds = getStockHoldingProductIds(
      configurableDetails.Configurable.Children,
    );
    stockHoldingProductIds.forEach((id) => {
      allProductIds.push(id);
    });
  }

  const products = allProductIds.map((id) => {
    return { ID: id, QuantityRequested: 1 };
  });

  const availability = await getProductAvailability({ products, locale });

  const prices = await getEvaService(Core.GetProductPrices, {
    ProductIDs: allProductIds,
  });

  const relations = relationsDetails.filter(
    (relationDetails): relationDetails is ProductDetails =>
      relationDetails !== null,
  );

  return transformExtendedProductDetail({
    details,
    availability,
    bundleDetails,
    configurableDetails,
    prices,
    relations,
  });
}

export async function getProductDetails(
  productId: number,
  locale: LocaleLanguageType,
) {
  try {
    const organizationUnitIDs = parseNumber(ORGANIZATION_UNIT_ID);
    if (organizationUnitIDs === null)
      throw Error('[getProductDetails] Invalid Organization Unit Id');

    return await getProductDetail(productId, locale);
  } catch {
    return null;
  }
}

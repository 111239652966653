/* eslint-disable camelcase */
import type { BundleLineBase } from '~source/core/models/bundle-line-base';
import type {
  AvailabilityIndication,
  Availability,
} from '~source/core/models/components/atoms/availability-indication';
import type { Price } from '~source/core/models/components/atoms/price';
import type {
  SizeModel,
  SizeModelConfigurable,
} from '~source/core/models/components/atoms/size';
import type {
  Printing,
  ProductDetails,
  SizelessProduct,
} from '~source/core/models/components/organisms/product-details';
import type { ProductsList } from '~source/core/models/components/organisms/products-list';
import transformProductsList from '~source/core/transformers/page-modules/transform-products-list';
import transformBadges, {
  transformBadge,
} from '~source/core/transformers/product/transform-badges';
import { getProductStatus } from '~source/core/transformers/product/transform-get-products';
import transformPlayerResponseToPlayerModel from '~source/core/transformers/product/transform-players';
import transformPricesResponseToPrice from '~source/core/transformers/product/transform-prices-response-to-price';
import transformProduct from '~source/core/transformers/product/transform-product';
import transformProductImages from '~source/core/transformers/product/transform-product-images';
import { isStock } from '~source/ui/utils/checks/is-product-type';
import capitalizeFirstLetter from '~source/ui/utils/formatters/capitalize-first-letter';
import getAvailabilityOfProduct from '~source/ui/utils/getters/get-availability-of-product';
import sortSizes from '~source/ui/utils/product/sort-sizes';
import { createProductDetailUrl as getProductDetailUrl } from '~source/ui/utils/urls/product-detail-url';

function transformBundleProductsToSizes(
  products: EVA.Core.GetBundleProductProduct[],
  availabilityOfProducts: AvailabilityIndication | null,
  lines: EVA.Core.BundleProductLine[],
): SizeModel[] {
  const productsWithSizes = products.filter(
    (product) => !!product.Content.size,
  );

  return productsWithSizes.reduce(
    (sizes: SizeModel[], { ID, Type, Content }) => {
      const { availability, availabilityDate } = getAvailabilityOfProduct(
        availabilityOfProducts,
        ID,
        Type,
      );

      const productBundleLineId = lines.find((line) =>
        line.Options.some((option) => option.ProductID === ID),
      )?.ID;

      const productBundleLineName = lines.find((line) =>
        line.Options.some((option) => option.ProductID === ID),
      )?.Description;

      if (!productBundleLineId) return sizes;

      return [
        ...sizes,
        {
          productBundleLineId,
          id: ID,
          label: Content?.size ?? null,
          shirtType: Content?.shirt_type ?? null,
          maxPrintLength: Content?.max_print_length ?? null,
          availability,
          availabilityDate,
          backendId: Content?.backend_id || '',
          name: capitalizeFirstLetter(productBundleLineName ?? ''),
        },
      ];
    },
    [],
  );
}

function transformConfigurableProductsToSizes(
  products: EVA.Core.ConfigurableProductDto[],
  availabilityOfProducts: AvailabilityIndication | null,
): SizeModelConfigurable[] {
  return products.map(({ ProductID, Value, Properties, Type }) => {
    const { availability, availabilityDate } = getAvailabilityOfProduct(
      availabilityOfProducts,
      ProductID,
      Type,
    );
    return {
      id: ProductID,
      label: Value,
      availability,
      availabilityDate,
      backendId: Properties?.backend_id || '',
    };
  });
}

function transformBundleLineOptionToBundleLineBase(
  option: EVA.Core.BundleProductLineOption,
) {
  return {
    id: option.ProductID,
    productBundleLineId: option.ProductBundleLineID,
  };
}

function transformProductRequirementToPrintOptionInfo(
  productsInLines: BundleLineBase[],
  // Any needed because EVA hasn't typed this part
  requirement: {
    id: number;
    data: any[];
    options: any;
  },
  bundleProduct: EVA.Core.GetBundleProductProduct,
  prices: EVA.Core.GetProductPricesResponse | undefined,
): Printing {
  const productBundleLineId = productsInLines.find(
    (product) => product.id === bundleProduct.ID,
  )?.productBundleLineId;
  const playerList = requirement.data || requirement.options?.EnumValues;

  return {
    printing: bundleProduct.Content.printing,
    price: transformPricesResponseToPrice(bundleProduct.ID, prices?.Prices),
    productBundleLineId: productBundleLineId as number,
    id: bundleProduct.ID,
    productRequirementValueId: requirement.id,
    isPlayer: !!playerList,
  };
}

function transformSizelessProducts(
  availabilityOfProducts: AvailabilityIndication | null,
  currentBundleLines: EVA.Core.BundleProductLine[],
  sizelessProduct: EVA.Core.GetBundleProductProduct[],
): SizelessProduct[] {
  return sizelessProduct.reduce(
    (acc: SizelessProduct[], product: EVA.Core.GetBundleProductProduct) => {
      const { ID, Content, Type } = product;
      const productBundleLine = currentBundleLines.find((line) =>
        line.Options.some((option) => option.ProductID === ID),
      );

      const { availability, availabilityDate } = getAvailabilityOfProduct(
        availabilityOfProducts,
        ID,
        Type,
      );

      if (!productBundleLine) return acc;

      return [
        ...acc,
        {
          id: ID,
          productBundleLineId: productBundleLine.ID,
          backendId: Content.backend_id,
          label: null,
          availability,
          availabilityDate,
        },
      ];
    },
    [],
  );
}

export default async function transformExtendedProductDetail({
  details,
  bundleDetails,
  configurableDetails,
  prices,
  availability: availabilityIndication,
  relations,
}: {
  details: EVA.Core.GetProductDetailResponse;
  bundleDetails?: EVA.Core.GetBundleProductDetailResponse;
  configurableDetails?: EVA.Core.GetConfigurableProductDetailResponse;
  prices?: EVA.Core.GetProductPricesResponse;
  availability: AvailabilityIndication | null;
  relations?: ProductDetails[] | null;
}): Promise<ProductDetails | null> {
  const {
    slug,
    primaryImage,
    productId,
    productTypes,
    descriptionShort,
    pickUp,
    delivery,
    reviews,
    sizeChart,
    itemType,
    relatedProductSlider: productSlider,
    relatedProductSliderTitle,
    relatedProductGrid: productGrid,
    relatedProductGridTitle,
    metaTitle,
    metaDescription,
    category,
    backendId,
    brand,
    displayValue,
    shirtType,
    currencyId,
    shippingInfoWithNoPrinting,
    shippingInfoWithPrinting,
    tsdBadge,
    tsdAsset,
  } = transformProduct(details);
  const isBundle = !!bundleDetails; // TODO(refactor): Perhaps use the EVA helpers for the product type check
  const isConfigurable = !!configurableDetails;

  // Start of Bundle or Configurable section
  const currentBundleLines = bundleDetails?.Lines.filter(
    (line) => !line.IsDeleted,
  );

  const getPlayers = () => {
    if (!bundleDetails) return null;
    const productsWithRequirements = bundleDetails.Products.filter(
      (product) => !!product.Content.product_requirements,
    );
    const productsWithPlayers = productsWithRequirements.find(
      (product) => product.Content?.item === 'spelers',
    );
    const players =
      productsWithPlayers?.Content?.product_requirements?.[0]?.data ||
      productsWithPlayers?.Content?.product_requirements?.[0]?.options
        ?.EnumValues;
    if (!players) return null;
    return transformPlayerResponseToPlayerModel(players);
  };

  const getBadges = () => {
    if (!bundleDetails || !currentBundleLines) return null;
    // Eventually "product.Content.categorie === 'badge'" can be removed
    const badges = bundleDetails.Products.filter(
      (product) =>
        (product.Content.categorie === 'badge' ||
          product.Content.item === 'badge') &&
        product.Content.hoofd_categorie !== 'logo',
    );

    return transformBadges({
      badges,
      lines: currentBundleLines,
      prices: prices?.Prices,
      availabilityOfProducts: availabilityIndication,
    });
  };

  const getSizes = () => {
    if (isBundle && currentBundleLines) {
      return transformBundleProductsToSizes(
        bundleDetails.Products,
        availabilityIndication,
        currentBundleLines,
      );
    }
    if (isConfigurable && configurableDetails)
      return transformConfigurableProductsToSizes(
        configurableDetails.Configurable.Children,
        availabilityIndication,
      );
    return null;
  };

  const getRequiredLogo = () => {
    if (!bundleDetails || !currentBundleLines) return null;
    const requiredLogo = bundleDetails.Products.find(
      (product) => product.Content.hoofd_categorie === 'logo',
    );

    if (requiredLogo)
      return transformBadge({
        badge: requiredLogo,
        lines: currentBundleLines,
        prices: prices?.Prices,
        availabilityOfProducts: availabilityIndication,
      });
    return null;
  };

  const getAdditionalPrintingOptions = () => {
    if (!bundleDetails || !currentBundleLines) return null;
    const printProducts = bundleDetails.Products.filter(
      (product) => !!product.Content.printing,
    );

    // Retrieve the productBundleLineIds of every product in the bundle to merge it later on into the printProducts object
    const productsInLines = currentBundleLines.reduce(
      (arr: BundleLineBase[], item) => {
        const products = item.Options.map(
          transformBundleLineOptionToBundleLineBase,
        );
        return [...arr, ...products];
      },
      [],
    );

    const transformedPrintProducts = printProducts.reduce(
      (arr: Printing[], bundleProduct) => {
        // any[] because EVA hasn't typed "Content"
        const productRequirements = bundleProduct.Content
          .product_requirements as any[];
        if (!productRequirements.length) return arr;
        const requirements = productRequirements.map((requirement) =>
          transformProductRequirementToPrintOptionInfo(
            productsInLines,
            requirement,
            bundleProduct,
            prices,
          ),
        );
        return [...arr, ...requirements];
      },
      [],
    );

    return transformedPrintProducts.length > 0
      ? transformedPrintProducts
      : null;
  };

  // A stock product can be a product without a size or a product that includes printing, badges, etc
  // Retrieve only products without a size from the bundle
  const getSizelessBundleProducts = () => {
    if (!bundleDetails || !currentBundleLines) return null;

    const sizelessProduct = bundleDetails.Products.filter((product) => {
      const { logical_level, printing, hoofd_categorie, categorie, item } =
        product.Content;

      return (
        isStock([product.Type]) &&
        logical_level !== 'size' &&
        !printing &&
        hoofd_categorie !== 'logo' &&
        (categorie !== 'badge' || item !== 'badge') &&
        item !== 'spelers'
      );
    });

    return transformSizelessProducts(
      availabilityIndication,
      currentBundleLines,
      sizelessProduct,
    );
  };
  // End of Bundle or Configurable section

  // Start of Price section
  const getPriceFromExtendedProductDetail = (): Price => {
    const noPrice = {
      original: null,
      sale: null,
    };
    return transformPricesResponseToPrice(productId, prices?.Prices) || noPrice;
  };
  // End of Price section

  // Start of Availability section
  const getAvailability = (): {
    availability: Availability;
    availabilityDate: string | null;
  } => {
    if (isBundle || isConfigurable)
      return {
        availability: 'unavailable',
        availabilityDate: null,
      };
    return getAvailabilityOfProduct(
      availabilityIndication,
      productId,
      productTypes[0],
    );
  };
  // End of Availability section

  // Start of Related Products section
  const sliderArray =
    productSlider && productSlider.replace(/ /g, '').split(',');

  const relatedProductSlider: ProductsList | null = sliderArray
    ? await transformProductsList({
        component: 'ProductSliderComponent',
        content: {
          products: sliderArray.map((s: string) => parseInt(s, 10)),
          text: relatedProductSliderTitle,
        },
      })
    : null;

  const gridArray = productGrid && productGrid.replace(/ /g, '').split(',');

  const relatedProductGrid: ProductsList | null = gridArray
    ? await transformProductsList({
        component: 'MinimalProductGridComponent',
        content: {
          products: gridArray.map((s: string) => parseInt(s, 10)),
          text: relatedProductGridTitle,
        },
      })
    : null;
  // End of Related Products section

  // Start of Custom Gateway section
  const getCustomGateway = () => {
    let customGateway = null;
    if (!details.Result) return customGateway;

    const {
      custom_gateway,
      custom_gateway_guid,
      custom_gateway_pid,
      product_requirements,
    } = details.Result;

    if (custom_gateway && custom_gateway_guid && custom_gateway_pid) {
      customGateway = {
        GuId: custom_gateway_guid,
        PId: custom_gateway_pid,
        PR: product_requirements,
      };
    }

    return customGateway;
  };
  // End of Custom Gateway section

  // Start of CarryOut section
  const getCarryOutStores = (): AvailabilityIndication['carryOutStores'] => {
    if (!availabilityIndication) return [];
    return availabilityIndication.carryOutStores;
  };
  // End of CarryOut section

  const players = getPlayers();
  const badges = getBadges();
  const sizes = getSizes();
  const printingOptionsInfo = getAdditionalPrintingOptions();
  const sizelessBundleProducts = getSizelessBundleProducts();
  const { availability, availabilityDate } = getAvailability();

  return {
    availability,
    availabilityDate,
    productInfo: descriptionShort || '',
    pickUp: pickUp || '',
    delivery: delivery || '',
    reviews: reviews || '',
    badges: isBundle ? badges : null,
    bundleId: isBundle ? bundleDetails.BundleProductID : null,
    displayValue,
    images: transformProductImages(details.Result),
    itemType,
    currencyId,
    players,
    primaryImage,
    price: getPriceFromExtendedProductDetail(),
    productId,
    productTypes,
    relatedProductSlider,
    relatedProducts: null,
    relatedProductGrid,
    requiredLogo: getRequiredLogo(),
    showConfigurator: !!printingOptionsInfo || !!badges,
    shirtType: shirtType ?? null,
    sizes: sizes && sortSizes(sizes),
    sizeChart: sizeChart ?? null,
    printingOptionsInfo,
    metaTitle,
    metaDescription,
    category,
    backendId,
    brand,
    shippingInfoWithNoPrinting: shippingInfoWithNoPrinting ?? null,
    shippingInfoWithPrinting: shippingInfoWithPrinting ?? null,
    customGateway: getCustomGateway(),
    path: getProductDetailUrl(slug, productId),
    productStatus: getProductStatus(
      Array.isArray(details.Result?.product_statuses)
        ? details.Result.product_statuses
        : [],
    ),
    stores: sizes ? [] : getCarryOutStores(),
    relations: relations ?? [],
    tsdBadge,
    tsdAsset,
    sizelessBundleProducts: sizelessBundleProducts ?? [],
  };
}

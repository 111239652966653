import * as React from 'react';
import {
  useTranslate,
  parseTranslationWithParams,
} from '~source/ui/hooks/helper/useTranslate/useTranslate';

type Props = {
  code: string;
} & Record<string, React.ReactNode>;

export default function Translate({ code, ...params }: Props) {
  const translate = useTranslate();
  const translation = React.useMemo(() => {
    const value = translate(code);
    return parseTranslationWithParams(value, (key) => {
      const part = params[key] ?? `{{${key}}}`;
      if (React.isValidElement(part)) {
        return React.cloneElement(part, { key });
      }

      return part;
    });
  }, [translate, code, params]);

  return <>{translation}</>;
}

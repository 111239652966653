/* eslint-disable camelcase */
import type { Product } from '~source/core/models/components/templates/product';
import { createProductDetailUrl } from '~source/ui/utils/urls/product-detail-url';

export default function transformProduct(
  response: EVA.Core.GetProductDetailResponse,
): Product {
  const { Result } = response;
  let categories;
  let category;
  let currencyId;
  let price = {
    original: 0,
    sale: null,
  };
  let displayValue;
  let primaryImage = {
    blob: '',
    name: '',
    type: '',
  };
  let productId = null;
  let slug = null;
  let size = null;
  let descriptionShort = null;
  let configurableProperties = [];
  let productTypes: EVA.Core.ProductTypes[] = [0];
  let productType: EVA.Core.ProductTypes = 0;
  let pickUp = null;
  let delivery = null;
  let reviews = null;
  let parentId = null;
  let relatedProductSlider = null;
  let relatedProductSliderTitle = null;
  let relatedProductGrid = null;
  let relatedProductGridTitle = null;
  let sizeChart = null;
  let itemType = null;
  let metaTitle = null;
  let metaDescription = null;
  let backendId = null;
  let brand = null;
  let shirtType = null;
  let shippingInfoWithNoPrinting = null;
  let shippingInfoWithPrinting = null;
  let tsdBadge = null;
  let tsdAsset = null;

  if (Result) {
    const {
      categories: resultCategories,
      currency_id,
      display_price,
      original_price,
      display_value,
      primary_image,
      product_id,
      slug: resultSlug,
      size: resultSize,
      short_description,
      configurable_properties,
      product_types,
      ophalen_fanshop,
      product_review,
      verzend_retour,
      parent_product_ids,
      product_type,
      related_product_slider,
      related_product_slider_titel,
      related_product_grid,
      related_product_grid_titel,
      maattabel,
      item,
      meta_title,
      meta_description,
      artikel_categorie,
      backend_id,
      merk,
      marketing_titel,
      shirt_type,
      cta_pdp_text,
      cta_pdp_text_prs,
      '270_value': tsd_badge,
      '270_asset': tsd_asset,
    } = Result;
    categories = resultCategories ?? null;
    category = artikel_categorie ?? null;
    currencyId = currency_id ?? null;
    relatedProductSlider = related_product_slider || '';
    relatedProductSliderTitle = related_product_slider_titel || '';
    relatedProductGrid = related_product_grid || '';
    relatedProductGridTitle = related_product_grid_titel || '';
    price = {
      original: (original_price || display_price) ?? null,
      sale: original_price ? display_price : null,
    };
    displayValue = (marketing_titel || display_value) ?? null;
    primaryImage = {
      blob: primary_image?.blob || '',
      name: primary_image?.name || display_value || '',
      type: primary_image?.type || '',
    };
    productId = product_id ?? null;
    slug = resultSlug ?? null;
    size = resultSize ?? null;
    descriptionShort = short_description ?? null;
    configurableProperties = configurable_properties ?? null;
    productTypes = product_types ?? null;
    productType = product_type ?? null;
    pickUp = ophalen_fanshop ?? null;
    delivery = verzend_retour ?? null;
    reviews = product_review ?? null;
    parentId = parent_product_ids?.[0] ? parent_product_ids[0] : null;
    sizeChart = maattabel ?? null;
    itemType = item || null;
    metaTitle = meta_title ?? null;
    metaDescription = meta_description ?? null;
    backendId = backend_id ?? null;
    brand = merk ?? null;
    shirtType = shirt_type ?? null;
    shippingInfoWithNoPrinting = cta_pdp_text ?? null;
    shippingInfoWithPrinting = cta_pdp_text_prs ?? null;
    tsdBadge = tsd_badge ?? null;
    tsdAsset = tsd_asset ?? null;
  }
  return {
    categories,
    category,
    currencyId,
    price,
    displayValue,
    primaryImage,
    productId,
    slug,
    size,
    descriptionShort,
    configurableProperties,
    productTypes,
    pickUp,
    delivery,
    reviews,
    parentId,
    productType,
    relatedProductSlider,
    relatedProductSliderTitle,
    relatedProductGrid,
    relatedProductGridTitle,
    path: createProductDetailUrl(slug, productId),
    sizeChart,
    itemType,
    metaTitle,
    metaDescription,
    backendId,
    brand,
    shirtType,
    shippingInfoWithNoPrinting,
    shippingInfoWithPrinting,
    tsdBadge,
    tsdAsset,
  };
}
